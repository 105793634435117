import React, {Children} from "react";
import {WelcomeSection} from "../components/Welcome";
import {cc, Emphasize, Heading, Italicize, Item, List, Paragraph, Subheading} from "../components/Typography";
import {ElementLoop} from "../components/ElementLoop";
import {LoginButton} from "../components/Authentication";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {Footer} from "../components/Footer";
import {ImprovementCalculatorRoute} from "../routes/ImprovementCalculator.route";
import {
    faHandHoldingSeedling,
    faRightToBracket,
    faTrophyStar,
    faUserMagnifyingGlass
} from "@fortawesome/pro-regular-svg-icons";
import {Link} from "react-router-dom";

export function LandingPage() {
    return <div className="flex flex-col w-full min-h-max flex-grow">
        <nav className="p-2 bg-white">
            <div className="max-w-screen-lg mx-auto px-4 flex justify-between rtl:space-x-reverse">
                <div></div>
                <LoginButton  bgClass="bg-soc-cheeks-dark hover:bg-soc-cheeks"><FontAwesomeIcon icon={faGoogle} /> &nbsp; Sign in</LoginButton>
            </div>
        </nav>
        <Section className="bg-gray-100"><WelcomeSection/></Section>
        <Section><DoYouRecognizeTheseChallenges /></Section>
        <Section><LetUsHelp /></Section>
        <Section><ImprovementCalculatorRoute compact/></Section>
        <Section className=""><Signup /></Section>
        <Section>
            <Paragraph className="italic mt-2 text-lg">
                Help us validate the problem we are looking to solve so we can build the right solution for you! Fill out <Link to="https://survey.socratesadvice.com/market-research" className="underline">our short survey</Link>.
            </Paragraph>
            <Paragraph className="italic my-2 text-lg">
                Want to learn where this is heading? Check out <Link to="https://pr.to/8IOA6T/" className="underline">our prototype</Link>.
            </Paragraph>
        </Section>
        <Section><Footer /></Section>
    </div>;
}

function Section({className, innerClassName = "max-w-screen-lg mx-auto px-4", children}) {
    return <div className={cc(className, "px-2 py-4")}>
        {Children.map(children, (child, index) => React.cloneElement(child, {
            key: index,
            className: cc(innerClassName, child.props.className)
        }))}
    </div>
}

function DoYouRecognizeTheseChallenges({className}) {
    return <div className={className}>
        <Heading>Are you a <ElementLoop className="bold text-soc-cheeks-dark">
            <>Scrum Master</>
            <>Team Lead</>
            <>Flow Master</>
            <>Project Manager</>
            <>Delivery Lead</>
        </ElementLoop> and recognize these challenges?</Heading>
        <Paragraph>
            <List>
                <>The team is working hard, but you realize there are <Emphasize>opportunities for working
                    smarter</Emphasize></>
                <>You have <Emphasize>neither the time, nor the support</Emphasize> to analyse what's happening and
                    coach the team</>
                <>You are responsible for <Italicize>getting things done</Italicize> and <Emphasize>focus on the
                    team’s delivery objectives</Emphasize></>
                <>Working with the product owner, technical leadership and the team keeps you <Emphasize>very
                    busy</Emphasize></>
                <>You feel <Emphasize>pressure to meet deadlines</Emphasize>, to fend of or integrate new requests
                    from stakeholders and address quality issues in production</>
            </List>
        </Paragraph>
    </div>;
}

function LetUsHelp({className}) {
    return <div className={className}>
        <Heading>Let us help you transform your Software Delivery team</Heading>
        <div className="bg-gray-100 -mx-4 p-4">

            <Paragraph>
                Is your software delivery team performing at its best? Discover opportunities to optimize your processes,
                improve collaboration, and achieve faster, more reliable results. Our unique assessment and coaching
                approach is designed to help you unlock your team's full potential.
            </Paragraph>

            <Subheading>How It Works</Subheading>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-4">
                <Tile icon={faRightToBracket}>1. Sign up</Tile>
                <Tile icon={faUserMagnifyingGlass}>2. Complete onboarding assessment</Tile>
                <Tile icon={faHandHoldingSeedling}>3. Get advice from a coach</Tile>
                <Tile icon={faTrophyStar}>4. Be awesome!</Tile>
            </div>

            {/*<List ordered>*/}
            {/*    <Paragraph>*/}
            {/*        <Emphasize>Complete Assessment</Emphasize>: Complete an initial assessment to help us learn more*/}
            {/*        about your team.*/}
            {/*    </Paragraph>*/}
            {/*    <Paragraph>*/}
            {/*        <Emphasize>Receive a Personalized Recommendation</Emphasize>: Following the assessment, one of*/}
            {/*        our coaches will meet with you to discuss the results and provide an actionable recommendation*/}
            {/*        specifically designed to enhance your team’s performance.*/}
            {/*    </Paragraph>*/}
            {/*</List>*/}
            {/*<Subheading>Why Choose Us?</Subheading>*/}
            {/*<List>*/}
            {/*    <Paragraph>*/}
            {/*        <Emphasize>Tailored Insights</Emphasize>: We understand that every team is different. That’s why*/}
            {/*        our assessment pinpoints the unique challenges your team faces.*/}
            {/*    </Paragraph>*/}
            {/*    <Paragraph>*/}
            {/*        <Emphasize>Expert Coaching</Emphasize>: Our coaches are seasoned professionals in software*/}
            {/*        delivery, ready to offer practical, real-world strategies.*/}
            {/*    </Paragraph>*/}
            {/*    <Paragraph>*/}
            {/*        <Emphasize>Actionable Next Step</Emphasize>: Leave the session with clear, actionable guidance to*/}
            {/*        drive an immediate improvement in your delivery cycle.*/}
            {/*    </Paragraph>*/}
            {/*</List>*/}
        </div>
    </div>
}

function Tile({icon, children}) {
    return <div className="text-center">
        <div><FontAwesomeIcon icon={icon} className="text-3xl bg-white rounded-full p-4" /></div>
        <div>{children}</div>
    </div>;
}

function Signup({className}) {
    return <div className={className}>
        <Heading>What do you need?</Heading>
        <Paragraph>
            All you need to get started is a Google account. Easy enough right?
        </Paragraph>
        <Paragraph>
            Take the first step towards delivering software - faster, more effectively and with greater impact.
        </Paragraph>
        <LoginButton className="w-full my-4"><FontAwesomeIcon icon={faGoogle} /> &nbsp; Sign in</LoginButton>
    </div>
}
